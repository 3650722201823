import styles from './getLocation.module.css'

interface Props {
  text: string
  icon: React.ReactNode
  onClick: () => void
}

const GetLocation: React.FC<Props> = ({ text, icon, onClick }) => {
  return (
    <button className={styles.getAddress} onClick={onClick}>
      {icon} {text}
    </button>
  )
}

export default GetLocation
