import styles from './doubleSlider.module.css'

interface Props {
  label: string
  min: number
  max: number
  valueMin: number
  valueMax: number
  setMin: (value: any) => void
  setMax: (value: any) => void
}

const DoubleSlider: React.FC<Props> = ({
  label,
  min,
  max,
  valueMin,
  valueMax,
  setMin,
  setMax,
}) => {
  return (
    <div className={styles.sliderContainer}>
      <p>
        <label htmlFor="range-2">{label}</label>
        <label htmlFor="range-3"></label>
      </p>
      <div className={styles.slider}>
        <div className={styles.sliderTrack} />
        <input
          aria-label="range min"
          id="range-2"
          type="range"
          min={min}
          max={max}
          value={valueMin}
          onChange={setMin}
        />
        <input
          aria-label="range max"
          id="range-3"
          type="range"
          min={min}
          max={max}
          value={valueMax}
          onChange={setMax}
        />
      </div>
    </div>
  )
}

export default DoubleSlider
