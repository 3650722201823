// import t from '../../../hooks/useFormatMessage'
import useFormatMessage from '../../../hooks/useFormatMessage'

import SearchIcon from '../../../assets/icons/search'

import styles from './address.module.css'

interface Props {
  value: string
  onChange: (value: any) => void
  onSubmit: (value: any) => void
}

const Address: React.FC<Props> = ({ value, onChange, onSubmit }) => {
  const t = useFormatMessage()

  return (
    <form className={styles.addressContainer} onSubmit={onSubmit}>
      <input
        type="text"
        placeholder={t('filter_input_placeholder')}
        value={value}
        onChange={onChange}
      />
      <button type="submit" aria-label="submit">
        <SearchIcon />
      </button>
    </form>
  )
}

export default Address
