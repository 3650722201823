import { useState } from 'react'
import {
  useJsApiLoader,
  // useLoadScript,
  GoogleMap,
  MarkerF,
  CircleF,
  InfoBoxF,
  InfoWindowF,
  // InfoBox,
  // InfoWindow
} from '@react-google-maps/api'

import type { Attributes } from 'parse'

// import Link from 'next/link'
import { useRouter } from 'next/router'

import { FormattedMessage } from 'react-intl'

// import t from '../../hooks/useFormatMessage'

import styles from './marker.module.css'

interface Props {
  job: Attributes
}

const Marker: React.FC<Props> = ({ job }) => {
  const [showTooltip, setShowTooltip] = useState(false)

  const router = useRouter()

  // console.log(job)
  const company = job.get('company')
  // const contact = company.get('contact')

  return (
    // <Link href={`/job/${job.id}`}>
    //   <a>
    <MarkerF
      key={job.id}
      position={{
        lat: job.attributes.location._latitude,
        lng: job.attributes.location._longitude,
      }}
      zIndex={999}
      animation={google.maps.Animation.DROP}
      icon={{
        url: `/images/marker/${job.attributes.jobCategory}.png`,
      }}
      onClick={() => {
        // console.log(job)
        // router.push(`/job/${job.id}`)
        router.push(
          `/job/${company.get('slug')}/${job.get('slug')}/${job.get(
            'city_slug'
          )}/${job.get('postcode')}/${company.id}/${job.id}`
        )
      }}
      onMouseOver={() => {
        setShowTooltip(true)
      }}
      onMouseOut={() => {
        setShowTooltip(false)
      }}
    >
      {showTooltip && (
        <InfoWindowF
          position={{
            lat: job.attributes.location._latitude,
            lng: job.attributes.location._longitude,
          }}
        >
          {/* <span>{job.attributes.description}</span> */}
          {/* <span dangerouslySetInnerHTML={{ __html: job.attributes.description }}></span> */}
          <div>
            <span className={styles.company}>
              {job.attributes.company.attributes.name}
            </span>
            <br />
            <span className={styles.description}>{job.attributes.name}</span>
            <br />
            {/* <span>- ab <span className={styles.bold}>{job.attributes.hoursPerWeek} Stunden</span> pro Woche</span> */}
            {/* <span>{t('map_tooltip_from', { hoursPerWeek: job.attributes.hoursPerWeek })}</span> */}
            {/* <FormattedMessage id="map_tooltip_from" values={{ b: chunks => <b>{chunks}</b>, hoursPerWeek: job.attributes.hoursPerWeek }} /> */}
            <FormattedMessage
              id="map_tooltip_from"
              values={{
                b: (chunks) => <span className={styles.bold}>{chunks}</span>,
                hoursPerWeek: job.attributes.hoursPerWeek,
              }}
            />
          </div>
        </InfoWindowF>
      )}
    </MarkerF>
    //   </a>
    // </Link>
  )
}

export default Marker
