import {
  useJsApiLoader,
  // useLoadScript,
  GoogleMap,
  MarkerF,
  CircleF,
} from '@react-google-maps/api'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import type { Attributes } from 'parse'

import { setAddress, setLocation } from '../../redux/slices/filterDataSlice'

import type { RootState } from '../../redux/store'

import Marker from '../marker'

import styles from './map.module.css'

const libraries: (
  | 'places'
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'visualization'
)[] = ['places']

interface Props {
  jobs?: Attributes[]
}

const Map: React.FC<Props> = ({ jobs }) => {
  const { locale } = useRouter()

  const options: google.maps.MapOptions = {
    disableDefaultUI: true,
    clickableIcons: false,
    zoomControl: true,
    // zoomControlOptions: {
    //   position: 12 // google.maps.ControlPosition.BOTTOM_RIGHT
    // },
    // zoom: 9
  }

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.NEXT_PUBLIC_MAPS_API_KEY!,
    libraries,
  })

  const filterData = useSelector((state: RootState) => state.filterData)
  const dispatch = useDispatch()

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${filterData.location.lat},${filterData.location.lng}&language=${locale}&result_type=postal_code&key=${process.env.NEXT_PUBLIC_MAPS_API_KEY}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.results.length) {
            const result = data.results[0].formatted_address
            dispatch(setAddress(result))
          }
        })
    }

    fetchData()
  }, [filterData.location.lat, filterData.location.lng, locale, dispatch])

  if (!isLoaded) return <p className={styles.mapWrapper}>Loading</p>

  return (
    isLoaded && (
      <div className={styles.mapWrapper}>
        <div className={styles.map}>
          <GoogleMap zoom={9} center={filterData.location} options={options}>
            <MarkerF
              position={filterData.location}
              zIndex={1000}
              draggable={true}
              animation={google.maps.Animation.DROP}
              icon={{ url: '/images/marker/home.png' }}
              onDragEnd={(e) => {
                dispatch(
                  setLocation({ lat: e.latLng!.lat(), lng: e.latLng!.lng() })
                )
              }}
            />
            <CircleF
              center={filterData.location}
              radius={filterData.radius * 1000}
              options={{
                strokeColor: '#000000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#000000',
                fillOpacity: 0.1,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
                zIndex: 1000,
              }}
            />
            {/* {jobs?.map((job) => (
              <MarkerF
                key={job.id}
                position={{
                  lat: job.attributes.location._latitude,
                  lng: job.attributes.location._longitude,
                }}
                zIndex={999}
                animation={google.maps.Animation.DROP}
                icon={{
                  url: `/images/marker/${job.attributes.jobCategory}.png`,
                }}
              />
            ))} */}
            {jobs?.map(
              (
                job,
                index // !index ? console.log(job.attributes) : null,
              ) => (
                <Marker key={job.id} job={job} />
              )
            )}
          </GoogleMap>
        </div>
      </div>
    )
  )
}

export default Map
