import Image from 'next/image'
import Link from 'next/link'

import type { Attributes } from 'parse'

import Spinner from '../../assets/icons/spinner'
import Building from '../../assets/icons/building'
import Location from '../../assets/icons/location'

import { useDispatch } from 'react-redux'
import { setShowBackButton } from '../../redux/slices/backButtonSlice'

// import t from '../../hooks/useFormatMessage'
import useFormatMessage from '../../hooks/useFormatMessage'

import styles from './jobs.module.css'

interface Props {
  jobs?: Attributes[]
  loading: boolean
}

const Jobs: React.FC<Props> = ({ jobs, loading }) => {
  const t = useFormatMessage()

  const dispatch = useDispatch()

  return (
    <div className={styles.jobs}>
      <div className={styles.count}>
        {!jobs || loading ? (
          <div>
            <div className={styles.spinner}>
              <Spinner />
            </div>
            {/* Loading... */}
            {t('jobs_searching')}...
          </div>
        ) : (
          <div>
            {jobs?.length} {t('jobs_results')}
          </div>
        )}
      </div>
      {jobs?.map((job) => (
        // <Link key={job.id} href={`/job/${job.id}`}>
        <Link
          // onClick={() => dispatch(setShowBackButton(true))}
          key={job.id}
          href={`/job/${job.get('company').get('slug')}/${job.get(
            'slug'
          )}/${job.get('city_slug')}/${job.get('postcode')}/${
            job.get('company').id
          }/${job.id}`}
        >
          <a
            className={styles.underline}
            onClick={() => dispatch(setShowBackButton(true))}
          >
            <div className={styles.job}>
              <div className={styles.imageContainer}>
                <Image
                  className={styles.img}
                  src={
                    job.attributes.company.attributes.logo_small?._url ||
                    '/images/company_logo_default.jpg'
                  }
                  alt={job.attributes.company.attributes.name}
                  layout="fill"
                  quality={100}
                />
              </div>
              <div>
                <h2>{job.attributes.name}</h2>
                <p className={styles.black}>
                  <span className={styles.building}>
                    <Building />
                  </span>
                  {job.attributes.company.attributes.name}
                  <span className={styles.pin}>
                    <Location />
                  </span>
                  {job.attributes.city}
                </p>
                {/* <p>{job.attributes.city}</p> */}
                <p className={styles.black}>
                  {!!job.attributes.hourlyRate && (
                    <>
                      <b>{`${job.attributes.hourlyRate}`.replace('.', ',')} €</b> / {t('jobs_hour')}{' '}
                      <b>|</b>
                    </>
                  )}{' '}
                  {t('filter_hourly_rate_start')}{' '}
                  <b>
                    {`${job.attributes.hoursPerWeek}`.replace('.', ',')} {t('jobs_hours')}
                  </b>{' '}
                  / {t('jobs_week')}
                </p>
                {/* <p className={styles.black}>
                  {t('filter_hourly_rate_start')} <b>{job.attributes.hoursPerWeek}{' '}
                  {t('jobs_hours')}</b> / {t('jobs_week')}
                </p> */}
              </div>
            </div>
          </a>
        </Link>
      ))}
    </div>
  )
}

export default Jobs
