import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import Image from 'next/image'

import type { RootState } from '../../redux/store'

import {
  setAddress,
  setLocation,
  setRadius,
  setHoursPerWeekMin,
  setHoursPerWeekMax,
  setHourlyRate,
  setJobTypes,
  setJobCategories,
} from '../../redux/slices/filterDataSlice'

import GetLocation from './getLocation'
import Address from './address'
import Slider from './slider'
import DoubleSlider from './doubleSlider'

// import t from '../../hooks/useFormatMessage'
import useFormatMessage from '../../hooks/useFormatMessage'

import LocationArrow from '../../assets/icons/locationArrow'

import styles from './filter.module.css'

// console.log(styles)

const Filter: React.FC = () => {
  const filterData = useSelector((state: RootState) => state.filterData)
  const dispatch = useDispatch()

  const { locale } = useRouter()

  const t = useFormatMessage()

  const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${filterData.address}&language=${locale}&result_type=postal_code&key=${process.env.NEXT_PUBLIC_MAPS_API_KEY}`

  const jobTypes = [
    { id: 0, name: 'parttimejob' },
    { id: 1, name: 'minijob' },
    { id: 2, name: 'temporaryjob' },
    { id: 3, name: 'weekendjob' },
    { id: 4, name: 'internship' },
    { id: 5, name: 'studentjob' },
  ]

  const jobCategories = [
    { id: 0, name: 'fastfood', image: 'fastfood.png', row: 'Gastronomie' },
    { id: 1, name: 'restaurant', image: 'restaurant.png', row: 'Gastronomie' },
    { id: 2, name: 'bar', image: 'bar.png', row: 'Gastronomie' },
    { id: 3, name: 'cafe', image: 'cafe.png', row: 'Gastronomie' },
    { id: 4, name: 'fashion', image: 'fashion.png', row: 'Einzelhandel' },
    { id: 5, name: 'groceries', image: 'groceries.png', row: 'Einzelhandel' },
    { id: 6, name: 'logistik', image: 'logistik.png', row: 'Dienstleistung' },
    { id: 7, name: 'others', image: 'others.png', row: 'Einzelhandel' },
    { id: 8, name: 'service', image: 'service.png', row: 'Dienstleistung' },
  ]

  const [selectedTypes, setSelectedTypes] = useState<Array<string>>(
    jobTypes.map((jobType) => jobType.name)
  )
  const [selectedCategories, setSelectedCategories] = useState<Array<string>>(
    jobCategories.map((jobCategory) => jobCategory.name)
  )

  const [filters, setFilters] = useState(false)

  useEffect(() => {
    setSelectedTypes(filterData.jobTypes)
    setSelectedCategories(filterData.jobCategories)
  }, [filterData.jobTypes, filterData.jobCategories])

  const toggleFilters = useCallback(() => setFilters((filters) => !filters), [])

  const handleAddressSubmit = async (e: any) => {
    e.preventDefault()

    await fetch(geocodeUrl)
      .then((res) => res.json())
      .then((data) => {
        dispatch(
          setAddress(
            data.results.length ? data.results[0].formatted_address : ''
          )
        )

        if (data.results.length) {
          dispatch(setLocation(data.results[0].geometry.location))
        }
      })
  }

  const handleUserLocation = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      const lat = position.coords.latitude
      const lng = position.coords.longitude
      dispatch(setLocation({ lat, lng }))

      // TODO: check if this is needed
      // if(window.google.maps.Geocoder) {
      //   const geocoder = new window.google.maps.Geocoder()
      //   geocoder.geocode({ location: { lat, lng } }, (result, status) => {
      //     if (status === window.google.maps.GeocoderStatus.OK) {
      //       // console.log(result)
      //       // const location = result?.filter(location => location.types.includes('street_address'))
      //       const location = result?.filter(location => location.types.includes('postal_code'))
      //         .sort((a, b) => a.geometry.location_type === 'ROOFTOP' ? 1 : -1)
      //         .pop()
      //       if(location) {
      //         console.log(location)
      //         // dispatch(setAddress(location.formatted_address))
      //         const formattedAddress = []
      //         let postalCode = null
      //         if(postalCode = location.address_components.find(component => component.types.includes('postal_code'))?.long_name)
      //           formattedAddress.push(postalCode, ' ')
      //         const country = location.address_components.find(component => component.types.includes('country'))?.long_name
      //         const city = location.address_components.find(component => component.types.includes('locality'))?.long_name
      //         formattedAddress.push(city, ', ', country)
      //         dispatch(setAddress(formattedAddress.join('')))
      //       }
      //     }
      //   })
      // }
    })
  }

  return (
    <div className={styles.filter}>
      <h1>{t('filter_header')}</h1>
      <div className={styles.sliderInputWrapper}>
        <h2>{t('filter_location')}</h2>
        <Address
          value={filterData.address}
          onChange={(e) => dispatch(setAddress(e.target.value))}
          onSubmit={handleAddressSubmit}
        />
        <GetLocation
          text={t('filter_determine_location')}
          icon={<LocationArrow />}
          onClick={handleUserLocation}
        />
        <Slider
          id="range-1"
          label={`${t('filter_radius_start')} ${filterData.radius} ${t(
            'filter_radius_end'
          )}`}
          value={filterData.radius}
          min={1}
          max={100}
          onChange={(e) => dispatch(setRadius(e.target.value))}
        />
      </div>

      <div className={styles.sliderInputWrapper}>
        <h2>{t('filter_hours_week_header')}</h2>
        <DoubleSlider
          label={`${filterData.hoursPerWeek[0]} - ${
            filterData.hoursPerWeek[1]
          } ${t('filter_hours_week')}`}
          min={1}
          max={48}
          valueMin={filterData.hoursPerWeek[0]}
          valueMax={filterData.hoursPerWeek[1]}
          setMin={(e) =>
            dispatch(setHoursPerWeekMin(Number.parseInt(e.target.value)))
          }
          setMax={(e) =>
            dispatch(setHoursPerWeekMax(Number.parseInt(e.target.value)))
          }
        />
      </div>

      <div className={styles.sliderInputWrapper}>
        <h2>{t('filter_hourly_rate_header')}</h2>
        <Slider
          id="range-4"
          label={`${t('filter_hourly_rate_start')} ${
            filterData.hourlyRate
          }€ ${t('filter_hourly_rate_end')}`}
          value={filterData.hourlyRate}
          min={0}
          max={40}
          onChange={(e) => dispatch(setHourlyRate(e.target.value))}
        />
      </div>

      <button className={styles.button} onClick={toggleFilters}>
        {t('filter_advanced_search')}
      </button>

      <div
        className={styles.filters + (!filters ? ' ' + styles.collapsed : '')}
      >
        <h2>{t('filter_job_type')}</h2>
        <div className={styles.checkboxList}>
          {jobTypes.map((jobType) => (
            <label key={jobType.id} className={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={selectedTypes.includes(jobType.name)}
                onChange={(event) => {
                  if (event.target.checked) {
                    setSelectedTypes((selectedTypes) => [
                      ...selectedTypes,
                      jobType.name,
                    ])
                    dispatch(setJobTypes([...selectedTypes, jobType.name]))
                  } else {
                    const updatedTypes = [...selectedTypes]
                    updatedTypes.splice(updatedTypes.indexOf(jobType.name), 1)
                    setSelectedTypes(updatedTypes)
                    dispatch(setJobTypes(updatedTypes))
                  }
                }}
              />
              {t(`filter_${jobType.name}`)}
            </label>
          ))}
        </div>

        <h2>{t('filter_job_category')}</h2>
        <div className={styles.checkboxList}>
          {jobCategories.map((jobCategory) => (
            <label key={jobCategory.id} className={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={selectedCategories.includes(jobCategory.name)}
                onChange={(event) => {
                  if (event.target.checked) {
                    setSelectedCategories((selectedCategories) => [
                      ...selectedCategories,
                      jobCategory.name,
                    ])
                    dispatch(
                      setJobCategories([
                        ...selectedCategories,
                        jobCategory.name,
                      ])
                    )
                  } else {
                    const updatedCategories = [...selectedCategories]
                    updatedCategories.splice(
                      updatedCategories.indexOf(jobCategory.name),
                      1
                    )
                    setSelectedCategories(updatedCategories)
                    dispatch(setJobCategories(updatedCategories))
                  }
                }}
              />
              <Image
                width="22"
                height="25"
                src={`/images/marker/${jobCategory.image}`}
                alt={jobCategory.name}
              />
              {t(`filter_${jobCategory.name}`)}
            </label>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Filter
