import styles from './slider.module.css'

interface Props {
  id: string
  label: string
  value: number
  min: number
  max: number
  onChange: (value: any) => void
}

const Slider: React.FC<Props> = ({ id, label, value, min, max, onChange }) => {
  return (
    <div className={styles.sliderContainer}>
      <p>
        <label htmlFor={id}>{label}</label>
      </p>
      <div className={styles.slider}>
        <input
          aria-label={label}
          id={id}
          type="range"
          min={min}
          max={max}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  )
}

export default Slider
